<template>
  <div class="enjoy gray-bcg-color">
    <c-banner :bannerData="bannerData"></c-banner>
    <div class="character white_bcg-color">
      <div class="character-content c-width">
        <c-title :titleArr="characterTitle"></c-title>
        <ul class="character-list">
          <li v-for="(item, index) in characterData" :key="index">
            <el-image :src="item.src" alt="" class="character-img"></el-image>
            <div class="title">{{ item.title }}</div>
            <div class="content">
              {{ item.content }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="advantage">
      <c-title :titleArr="advantageTitle"></c-title>
      <ul class="advantage-list c-width">
        <li class="title item">
          <div></div>
          <div>功 能 覆 盖</div>
          <div>研 发 成 本</div>
          <div>运 维 成 本</div>
          <div>资 产 投 入</div>
          <div>数 据 安 全</div>
        </li>
        <li
          class="item"
          :class="{ enjoy: item.className }"
          v-for="(item, index) in advantageData"
          :key="index"
        >
          <div class="item-title">{{ item.title }}</div>
          <div class="text" v-for="(text, idx) in item.textData" :key="idx">
            <span>{{ text }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="deploy white_bcg-color">
      <div class="deploy-conent c-width">
        <c-title :titleArr="deployTitle"></c-title>
        <div class="img-wrap">
          <el-image
            :src="require('../../../assets/images/enjoy/deploy.png')"
            alt=""
            lazy
          ></el-image>
        </div>
      </div>
    </div>
    <div class="function c-width">
      <c-title :titleArr="functionTitle"></c-title>
      <ul class="function-list">
        <li
          class="function-item"
          v-for="(item, index) in functionData"
          :key="index"
        >
          <div class="title show-btn">{{ item.title }}</div>
          <div class="l-circle"></div>
          <div class="s-circle"></div>
          <div class="text show-btn" v-for="text in item.textData" :key="text">
            {{ text }}
          </div>
        </li>
      </ul>
    </div>
    <div class="function-detail white_bcg-color">
      <div class="detail-content c-width">
        <c-title :titleArr="functionDetailTitle"></c-title>
        <div class="sub-title">
          体系功能完备，一站式完成MVP(Minimum Viable Product)
        </div>
        <ul class="detail-list">
          <li
            class="detail-item"
            v-for="(item, index) in functionDetailData"
            :key="index"
          >
            <div class="title">{{ item.title }}</div>
            <div class="text" v-for="text in item.textData" :key="text.index">
              {{ text }}
            </div>
          </li>
        </ul>
        <div class="common-function">
          <div class="title">常见音视频功能快速集成定制，追平行业翘楚</div>
          <div>
            <img
              src="../../../assets/images/enjoy/common-function.png"
              alt=""
              width="852"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="customized">
      <h3>提交您的需求，我们为您定制</h3>
      <a :href="custonHref" target="_blank">申请定制</a>
    </div>
  </div>
</template>

<script>
import { cBanner } from '../components'
import { cTitle } from '@/components'
import { getCookieToken } from '../../../utils.js'
export default {
  components: { cBanner, cTitle },
  data () {
    return {
      custonHref: `//${window.location.host}/custom?refer=enjoy`,
      isLogin: false,
      advantageData: [
        {
          className: '',
          title: '微吼SaaS',
          textData: [
            '支持常见音视频功能，功能固定，无法贴合自定义业务场景',
            '几乎为零',
            '统一公用的平台运维服务，无须运维成本',
            '无须硬件资产投入',
            '公用SaaS软件数据托管服务'
          ]
        },
        {
          className: 'enjoy',
          title: '轻享直播私有化',
          textData: [
            '在常见音视频相关功能可选购的基础上，支持按需求功能定制',
            '几乎为零',
            '可提供专业运维团队，独立代运维',
            '按需灵活部署硬件资源，合理利用已存资产，资产投入可控',
            '独享应用服务及其数据存储，实现数据隔离'
          ]
        },
        {
          className: '',
          title: '自研',
          textData: [
            '自由定义功能',
            '需要音视频研发团队、业务研发两个团队，人力成本高',
            '音视频网络线路部署维护复杂，不能及时应对各种突发状况，需要自建运维团队，运维要求高',
            '硬件投入成本高，固定资产利用率低',
            '自行管理数据安全保障，要求高成本大'
          ]
        }
      ],
      functionDetailData: [
        {
          title: '直播点播',
          textData: [
            '发起直播',
            '第三方推流',
            '在线观看',
            '自动默认回放',
            '点播管理'
          ]
        },
        {
          title: '互动聊天',
          textData: ['实时互动', '16路并发', '旁路直播', '聊天管理', '禁言拉黑']
        },
        {
          title: '文档演示',
          textData: [
            '文档演示',
            '白板画笔',
            '多格式转码',
            '在线预览',
            '文档管理'
          ]
        },
        {
          title: '数据统计',
          textData: ['PV/UV', '消耗统计', '终端类型', '地域分布', '数据导出']
        }
      ],
      functionDetailTitle: ['功能详情'],
      functionData: [
        {
          title: 'IOS App',
          textData: ['登录注册', '音视频房间', '用户体系', '房间列表管理']
        },
        {
          title: 'Android APP',
          textData: ['登录注册', '音视频房间', '用户体系', '房间列表管理']
        },
        {
          title: 'Web端用户后台',
          textData: [
            '登录注册',
            '音视频房间',
            '用户体系',
            '房间列表管理',
            '聊天管理',
            '文档问卷管理',
            '活动流程',
            '数据统计'
          ]
        },
        {
          title: 'Web端Admin管理后台',
          textData: [
            '平台业务管理',
            '资源功能管理',
            '用户体系管理',
            '管理员体系'
          ]
        }
      ],
      functionTitle: ['方案功能架构'],
      deployTitle: ['方案部署架构'],
      advantageTitle: ['方案优势'],
      characterData: [
        {
          src: require('../../../assets/images/enjoy/all.png'),
          title: '简约而全面',
          content:
            '麻雀虽小功能俱全，包括直播、点播、互动、互动直播、文档演示白板演示、聊天等全面的能力，涵盖常见的音视频用法及功能，灵活而快速的将业务落地其中。'
        },
        {
          src: require('../../../assets/images/enjoy/control.png'),
          title: '自主掌控',
          content:
            '轻享直播私有化部署后，属于应用层的数据和逻辑，企业可完全自主掌控。同时底层音视频能力享受高性价比的微吼云公有云服务。'
        },
        {
          src: require('../../../assets/images/enjoy/custom.png'),
          title: '可定制化',
          content:
            '根据企业业务场景，定制个性化的产品功能特性和技术架构，全程实施保障和运维保障，安心交付。'
        }
      ],
      characterTitle: ['方案特点'],
      bannerData: {
        ways: 'enjoy',
        title: '轻享直播定制解决方案',
        text: [
          '轻量级的应用层直播互动平台，帮助企业快速对接微吼云，配合微吼云PaaS能力，',
          '在业务层快速搭建一套包含常见音视频功能的应用层平台，按需功能定制还可与自身业务深度整合。'
        ],
        href2: 'https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038',
        href1: '',
        text1: '立即体验',
        text2: '立即咨询'
      }
    }
  },
  created () {
    this.isLogin = getCookieToken()
    this.bannerData.href1 = this.isLogin
      ? 'https://private.vhallyun.com/frontend/index.html#/login'
      : `${window.urlConfig.consoleUrl}loginLayout/regist`
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.character {
  padding: 40px 0 90px;
}
h3 {
  padding: 0;
  margin: 0;
}
.character-list {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 70px;
  .title {
    font-size: 24px;
    color: #000;
    font-weight: 500;
    margin-top: 30px;
    text-align: center;
  }
  .character-img {
    width: 70px;
  }
  .content {
    margin-top: 24px;
    max-width: 340px;
    font-size: 16px;
    color: #555;
  }
}
.advantage {
  padding: 70px 0;
}

.deploy {
  padding: 80px 0;
  .img-wrap {
    margin-top: 30px;
    img {
      width: 100%;
    }
  }
}

.function {
  padding: 70px 0 50px;
  .function-list {
    margin-top: 54px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .function-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .show-btn {
        width: 202px;
        height: 45px;
        margin-top: 15px;
        border-radius: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
      }
      .l-circle {
        width: 12px;
        height: 12px;
        margin: 10px 0;
        border-radius: 50%;
        background: @mainColor;
      }
      .s-circle {
        width: 8px;
        height: 8px;
        margin-bottom: 10px;
        border-radius: 50%;
        background: @mainColor;
      }
      .title {
        color: #fff;
        background: @mainColor;
        font-weight: 500;
      }
      .text {
        background: #fff;
        box-shadow: @boxShadow;
        color: #524f71;
        font-weight: 500;
      }
    }
  }
}

.function-detail {
  padding-top: 50px;
  .sub-title {
    margin-top: 50px;
    text-align: center;
    color: #333;
    font-size: 24px;
  }
  .detail-list {
    margin: 50px 0;
    display: flex;
    justify-content: space-between;
    .detail-item {
      width: 100%;
      padding: 20px;
      box-shadow: @boxShadow;
      border-radius: 8px;
      border: 1px solid #dedede;
      &:not(:first-child) {
        margin-left: 30px;
      }
      .title {
        display: inline-block;
        padding: 15px 20px;
        margin-left: -20px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background: @mainColor;
        box-shadow: 0px 3px 7px 0px rgba(90, 156, 225, 1);
      }
      .text {
        padding: 15px 0;
        font-size: 16px;
        color: #666;
        text-align: center;
        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
  .common-function {
    text-align: center;
    position: relative;
    padding-bottom: 100px;
    .title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      top: 50px;
      color: #333;
      font-size: 22px;
    }
  }
}
.customized {
  width: 100%;
  height: 280px;
  background: url('../../../assets/images/enjoy/custon-bcg.png') no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    padding-top: 56px;
    font-size: 36px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  a {
    display: inline-block;
    margin-top: 78px;
    width: 130px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #fff;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    &:hover {
      background: #fff;
      color: @mainColor;
    }
  }
}

.advantage-list {
  height: 696px;
  display: grid;
  grid-template-columns: 76px 337px 450px 337px;
  margin-top: 77px;
  align-items: center;
  .item {
    box-shadow: 0px 0px 5px 0px rgba(213, 197, 231, 0.68);
    &.title {
      line-height: 76px;
      background: #eaf2ff;
      div {
        writing-mode: vertical-lr;
      }
    }
    div {
      height: 108px;
      font-weight: 500;
      color: #666;
      font-size: 16px;
      text-align: center;
      border-bottom: 1px solid #fff;
      &:first-child {
        width: 100%;
        height: 90px;
      }
    }
    .item-title {
      background: #f7faff;
      line-height: 91px;
      color: #333;
      font-weight: 500;
      font-size: 22px;
    }
    .text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #f5f8f9;
      background: #fff;
      span {
        display: inline;
        max-width: 260px;
      }
    }
    &.enjoy {
      position: relative;
      z-index: 2;
      height: 100%;
      box-shadow: @boxShadow;
      div {
        border-bottom: 1px solid #f5f8f9;
      }
      .item-title {
        background: #fff;
        height: 122px;
        line-height: 122px;
        color: @mainColor;
        box-sizing: border-box;
      }
      .text {
        &:last-child {
          height: 139px;
        }
      }
    }
  }
}
</style>
